export var encodeNotes = function () {
    var elements = document.querySelectorAll('.notesTextarea, .notesTextareaEdit, .notesTextareaAdd, .notesTextareaAddClient .TextareaAdd');
    elements.forEach(function (element) {
        element.value = element.value.replace(/</g, '').replace(/>/g, '');
        element.value = element.value.replace(/(([&]+)#)/ig, '');
    });
};
export var encodeActivities = function () {
    var elements = document.querySelectorAll('.ActivityTextAreaAdd, .ActivityTextAreaEdit');
    elements.forEach(function (element) {
        element.value = element.value.replace(/</g, '').replace(/>/g, '');
        element.value = element.value.replace(/(([&]+)#)/ig, '');
    });
};
